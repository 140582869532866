<template>
    <modal @close_modal="close_modal" :title="computedTitle" :loading="loading">
      <template v-slot:content>
          <v-row>
            <col12>
              <!-- Filter Tree Component -->
              <filter-tree
                @set_value="set_value"
                @update="update"
                @clear_filter_id="clear_filter_id"
                :filters="updated_filters"
                :max_results="false"
                :show_limit="false"
                :show_button="false"
                :initial_filter="filters"
                @add_filter="add_filter"
              ></filter-tree>
            </col12>
          </v-row>
      </template>
  
      <template v-slot:buttons>
        <v-btn
          color="primary"
          text
          id="cancel_button"
          @click.native="cancel"
        >
          Cancel
        </v-btn>
  
        <v-btn
          color="primary"
          text
          id="save_button"
          @click.native="save"
        >
          Save
          <v-icon
            dark
            primary
            right
          >
            fas fa-level-down-alt fa-rotate-90
        </v-icon>
        </v-btn>
      </template>
    </modal>
  </template>
  
  <script>
  import Modal from "@/components/Modal";
  import FilterTree from "@/components/asset_filter/FilterTree"; // Assuming this is the correct path
  import Col12 from "@/components/layout/Col12";
  const { v4: uuidV4 } = require('uuid');
  
  export default {
    name: "FilterTreeModal",
    components: { Col12, FilterTree, Modal },
    props: {
      'filters': { required: true },
      'title': { default: null }
    },
    computed: {
      translatedTitle() {
        return this.$t('settings_tasks.edit_title_filter_tree');
      },
      computedTitle() {
        return this.title !== null ? this.title : this.translatedTitle;
      }
    },
    data() {
      return {
        updated_filters: [],
        loading: false,
      };
    },
    mounted() {
      this.$set(this, 'updated_filters', this.filters || []);
    },
    methods: {
      add_filter(filter) {
        filter.id = uuidV4();
        if (filter.options && filter.options.length > 0 && !filter.value && filter.options[0] === 'Yes') {
          filter.value = filter.options[0];
        }
        this.filters.push(filter);
      },
      update(filters) {
        this.$set(this, 'updated_filters', filters);
      },
      clear_filter_id(filter_id) {
        let filters = this.filters.filter(f => f.id !== filter_id);
        this.$set(this, 'filters', filters);
      },
      set_value(filter_id, value) {
        let filterIds = this.filters.map(f => f.id);
        let i = filterIds.indexOf(filter_id);
        this.$set(this.filters[i], 'value', value);
      },
      cancel() {
        this.$emit('close_modal');
      },
      save() {
        console.log(this.updated_filters);
        // Add logic to save the filter tree settings or any related data.
        this.$emit('save', this.updated_filters);
        this.$emit('close_modal');
      },
      close_modal() {
        this.$emit('close_modal');
      }
    }
  };
  </script>
  
  <style scoped>
  /* Add any custom styles here if needed */
  </style>
  