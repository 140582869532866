<template>
  <div>
    <div style="height:40px; display: flex; align-items: center;" v-if="zoom_enabled">
      <v-btn
        class="ma-1"
        icon
        @click.native="resetZoom"
        color="blue lighten-2"
      >
        <v-icon>fa-solid fa-expand</v-icon>
      </v-btn>

      <div style="padding-left: 8px; padding-top: 11px;">
        {{pretty_annotation_count}}
      </div>

      <div v-if="is_embedding_loading" style="margin-left: auto; padding-top: 11px;">
        Segment Anything
        <v-progress-circular
          :size="24"
          :width="2"
          indeterminate
          color="primary"
        ></v-progress-circular>
      </div>
    </div>
    <div v-else height="40px;">{{pretty_annotation_count}}</div>
    <canvas :id="canvas_id" width="640px" height="500px"></canvas>
  </div>
</template>

<script>

import BboxCanvas from "@/bbox_canvas";
import Annotation from '@/classes/annotation'
import { fabric } from "fabric";
const { v4: uuidV4 } = require('uuid');

export default {
  name: "AnnotatedImage",
  components: {},
  props: {
    image_url: {required: true},
    zoom_enabled: {default: true},
    annotations: {required: true},
    max_height: {default: false,},
    brightness: {default: 1.0},
    contrast: {default: 1.0},
    is_embedding_loading: {default: false},
  },
  data () {
    return {
      'canvas_id': 'custom_id',
      'canvas': false,
      'zoom_factor': 1,
      'view_port_transforms': [0,0,0,0,0,0],
      'draw_annotations': true,
      'last_annotation_added': false
    }
  },
  mounted () {
    this.canvas_id = this.getUniqueId();
    if(this.image_url) {
      this.load();
    }
  },
  watch: {
    brightness(v) {
      this.canvas.updateBrightness(v);
    },
    contrast(v) {
      this.canvas.updateContrast(v);
    },
    image_url() {
      this.draw_annotations = false;
      this.load();
    },
    annotations(n) {
      if(!this.draw_annotations) {
        return;
      }
      if(this.canvas) {

        let annotation_selected = false
        let anything_updated = false;

        let this2 = this;
        let existing_annotations = this.canvas.getAnnotations();
        let existing_jsons = existing_annotations.map(existing=>{return JSON.stringify(existing)})
        let new_ids = n.map(new2=>{return new2.id})
        let existing_ids = existing_annotations.map(exist=>{return exist.id})

        existing_annotations.forEach(annotation=>{
          if(!new_ids.includes(annotation.id)) {
            this2.deleteAnnotation(annotation);
            anything_updated = true;
          }
        })
        n.forEach(annotation=>{
          if(annotation.selected) {
            annotation_selected = true;
          }
          let new_json = JSON.stringify(annotation);
          let equals = existing_jsons.filter(existing_json=>{return existing_json===new_json});
          if(equals.length === 0) {
            anything_updated = true;
            this2.deleteAnnotation(annotation);
            if (annotation.show) {
              //TODO canvas add or update annotation?
              this2.addAnnotation(annotation, !existing_ids.includes(annotation.id));
            }
          }
        })

        if(anything_updated && annotation_selected) {
          this.hideText();
        }
        else if (anything_updated) {
          this.showText();
        }

        if(anything_updated) {
          this.canvas.selectAllAnnotations(this.annotations_selected)
        }
      }
    },
  },
  computed: {
    'annotations_selected' () {
      return this.annotations.filter(a=>{return a.selected === true});
    },
    'annotation_selected' () {
      let selected = this.annotations.filter(a=>{return a.selected === true});
      if(selected.length>0) return selected[0];
      return false;
    },
    pretty_annotation_count () {
      let n = this.annotations.length;
      if (n===1) return n + this.$t('set_label_groups.edit_annotation')
      else return n + this.$t('set_label_groups.edit_annotations');
    }
  },
  methods: {
    eventRightMousePressed(e) {
      if(this.last_annotation_added) {
        let clone = JSON.parse(JSON.stringify(this.last_annotation_added))
        clone.id = uuidV4();
        clone.skip_zoom = true
        clone.coords[0] = [e.detail.x]
        clone.coords[1] = [e.detail.y]
        clone.skip_zoom = false;
        clone.selected = false;
        this.$emit('add_annotation_deselect_others', clone)
        this.last_annotation_added = clone
      }
    },
    addEventsKeyUp: function(e) {
      let this2 = this;
      if(e.keyCode === 16) { // shift
        this2.canvas.shift_pressed = false
      }
      if(e.keyCode === 18) { // alt
        this2.canvas.alt_pressed = false
      }
      if(e.keyCode === 83) { // s
        this2.canvas.s_pressed = false
      }
    },
    addEventsKeyDown: function(e) {
      let this2 = this;
      if(e.keyCode === 16) { // shift
        this2.canvas.shift_pressed = true
      }
      if(e.keyCode === 18) { // alt
        this2.canvas.alt_pressed = true
      }
      if(e.keyCode === 83) { // s
        this2.canvas.s_pressed = true
      }
    },
    resetZoom() {
      this.canvas.resetZoom();
    },
    hideText() { // aka only label for selected
      let this2 = this;
      this2.canvas.hideText();
    },
    showText() { // aka show lables for all
      let this2 = this;
      this2.canvas.showText();
    },
    removeEventListeners() {
      window.removeEventListener('keydown', this.addEventsKeyDown);
      window.removeEventListener('keyup', this.addEventsKeyUp);
      window.removeEventListener(this.canvas_id + 'delete_annotation', this.eventDeleteAnnotation);
      window.removeEventListener(this.canvas_id + 'add_annotation', this.eventAddAnnotation);
      window.removeEventListener('update_coords', this.eventUpdateCoords);
      window.removeEventListener('select_annotation', this.eventSelectAnnotation);
      window.removeEventListener('unselect_annotation', this.eventUnselectAnnotation);
      window.removeEventListener(this.canvas_id + 'select', this.eventCanvasSelect);
      window.removeEventListener(this.canvas_id + 'canvas_selected', this.eventCanvasSelect2);
      window.removeEventListener(this.canvas_id + '_zooming', this.eventSetZoomFactor);
      window.removeEventListener(this.canvas_id + '_dragging', this.eventSetViewPointTransforms);
      window.removeEventListener(this.canvas_id + 'right_mouse_pressed', this.eventRightMousePressed);
      window.removeEventListener(this.canvas_id + 'canvas_mousedown', this.eventCanvasMouseDown);

    },
    eventSetZoomFactor(e) {
      this.$set(this, 'zoom_factor', e.detail.zoom)
    },
    eventSetViewPointTransforms(e) {
      this.$set(this, 'view_port_transforms', e.detail.view_port_transform)
    },
    eventDeleteAnnotation(e) {
      let annotation_id = e.detail.id;
      this.$emit('delete_annotation', annotation_id);
    },
    eventAddAnnotation(e) {
      // let annotation_id = e.detail.id;
      this.$emit('add_annotation', e.detail.annotation);
    },
    eventCanvasMouseDown(e) {
      if(this.canvas.s_pressed) {
        this.$emit('canvas_mousedown', e.detail)
      }
      // this.$emit('canvas_mousedown', e.detail)
    },
    eventUpdateCoords(e) {
      this.$emit('update_coords', e.detail.id, e.detail.coords);
    },
    eventSelectAnnotation(e) {
      if(!this.zoom_enabled) {
          this.$emit('canvas_selected')
        }
        else if(e.detail.id) {
          this.$emit('select', e.detail.id, this.canvas.shift_pressed);
          this.hideText();
        }
    },
    eventUnselectAnnotation(e) {
      if(!this.zoom_enabled) {
          this.$emit('canvas_selected')
        }
        else if(e.detail.id) {
          this.$emit('unselect', e.detail.id);
        }
    },
    eventCanvasSelect(e) {
      this.canvas.selectAnnotation(e.detail.id);
    },
    eventCanvasSelect2(e) {
      // Might be the case that an annotation is selected as well
      this.$emit('canvas_selected')

      // If there is no annotation_id included, only the canvas is selected
      if(!e.detail.annotation_id) {
        this.showText();
      }
    },
    addEventListeners() {
      window.addEventListener('keydown', this.addEventsKeyDown);
      window.addEventListener('keyup', this.addEventsKeyUp);
      window.addEventListener(this.canvas_id + 'delete_annotation', this.eventDeleteAnnotation);
      window.addEventListener(this.canvas_id + 'add_annotation', this.eventAddAnnotation);
      window.addEventListener('update_coords', this.eventUpdateCoords);
      window.addEventListener('select_annotation', this.eventSelectAnnotation);
      window.addEventListener('unselect_annotation', this.eventUnselectAnnotation);
      window.addEventListener(this.canvas_id + 'select', this.eventCanvasSelect);
      window.addEventListener(this.canvas_id + 'canvas_selected', this.eventCanvasSelect2);
      window.addEventListener(this.canvas_id + '_zooming', this.eventSetZoomFactor);
      window.addEventListener(this.canvas_id + '_dragging', this.eventSetViewPointTransforms);
      window.addEventListener(this.canvas_id + 'right_mouse_pressed', this.eventRightMousePressed);
      window.addEventListener(this.canvas_id + 'canvas_mousedown', this.eventCanvasMouseDown);

    },
    addAnnotation(annotation, newly_added) {
      //Meerdere add Annotation functies?
      if(newly_added) {
        this.last_annotation_added = annotation;
        annotation.selected = true;
      }
      this.canvas.addAnnotation(annotation, newly_added);
    },
    deleteAnnotation(annotation) {
      this.canvas.deleteAnnotation(annotation);
    },
    addBox() {
      let id = this.getUniqueId();
      let coords = [40, 40, 100, 140]
      let annotation = new Annotation(id, 'box', coords, 'Label', 1.0, [180,0,0]);
      this.addAnnotation(annotation);
    },
    addPoint() {
      let id = this.getUniqueId();
      let coords = [140, 120]
      let annotation = new Annotation(id, 'point', coords, 'Label', 1.0, [180,0,0]);
      this.addAnnotation(annotation);
    },
    getUniqueId() {
      return 'canvas' + Math.random().toString(36).slice(2);
    },
    load() {
      let this2 = this;
      this2.removeEventListeners();
      if(this2.canvas.canvas) {
        this2.canvas.canvas.dispose();
        this2.$set(this2, 'zoom_factor', 1);
        this2.$set(this2, 'view_port_transforms', [0,0,0,0,0,0]);
      }
      this2.$set(this2, 'canvas_id', this2.getUniqueId());

      let image_url_with_token = this.image_url + '?token=' + localStorage.token;
      if (image_url_with_token) {
        this.$emit('image_url_with_token', image_url_with_token);
      }

      fabric.Image.fromURL(image_url_with_token, function(image, err) {
        if(err) {
            console.log('error', err);
        }

        this2.canvas = new BboxCanvas(this2.canvas_id, image, false, false, !this2.zoom_enabled, this2.max_height);
        this2.addEventListeners();
        this2.draw_annotations = true;

        this2.annotations.forEach(annotation=>{
          this2.addAnnotation(annotation);
        })

      });
    }
  }
}
</script>

<style scoped>

</style>