<template>
  <div>
      <div v-for="(filter, index) in filters" :key="index"> 
        <v-chip 
          @click="editFilter(index)"
        >{{ 'Filter ' + (index + 1) }}
        <v-icon small right @click="deleteFilter(index)">fas fa-trash-alt</v-icon>
      </v-chip>
      </div>
      <filter-in-modal @save="save" title="Edit filter" :filters="activeFilter" v-if="showFilterModal" @close_modal="closeFilterModal"></filter-in-modal>
      <icon-button @pressed="addFilter" icon="fas fa-plus"></icon-button>
  </div>
</template>

<script>

import FilterInModal from "@/components/train_configs/FilterInModal";
import IconButton from "@/components/form/IconButton";

  export default {
    name: "FilterList",
    components: {FilterInModal, IconButton},
    data() {
      return {
        showFilterModal: false,
        activeFilterIndex: 0,
      }
    },
    props: {
      'json_filters': {required: true},
    },
    methods: {
      save(value) {
        this.filters[this.activeFilterIndex] = value;
        this.$emit('update', JSON.stringify(this.filters));
        this.showFilterModal = false;
      },
      deleteFilter(index) {
        this.filters.splice(index, 1);
        this.$emit('update', JSON.stringify(this.filters));
      },
      editFilter(index) {
        this.$set(this, 'activeFilterIndex', index);
        this.$set(this, 'showFilterModal', true);
      },
      closeFilterModal() {
        this.$set(this, 'showFilterModal', false);
      },
      addFilter() {
        let newFilter = '';
        this.filters.push(newFilter);
        this.$emit('update', JSON.stringify(this.filters));
      }
    },
    computed: {
      activeFilter() {
        return this.filters[this.activeFilterIndex];
      },
      filters () {
        if (!this.json_filters) {
          return [];
        }
        return JSON.parse(this.json_filters);
      }
    },
  }
</script>

<style scoped>

</style>