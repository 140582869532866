<template>
  <menu-layout :loading="loading">
    <row>
      <col3>
        <filter-tree
            :initial_filter="[
                {'type':27, 'value':''},
                {'type':23, 'value':'Yes'},
                {'type':9, 'value':'No'},
                {'type':11, 'value':'No'},
                {'type':7, 'value':''}
               ]"
            @update="update"
            @load="load" :max_results="max_results"></filter-tree>
      </col3>
      <col9>
        <row>
          <col12>
              <asset-grid @reload="load" :items="items" v-on:remove_item="removeGridItem"
                        v-on:update_selection_asset_ids="update_selection">
                <template v-slot:menu>
                  <row>
                    <col2>
                      <dropdown-menu v-if="items.length > 0"
                                     :menu_items="menu_items"
                      ></dropdown-menu>
                    </col2>
                  </row>
                </template>
              </asset-grid>
          </col12>
        </row>
      </col9>
    </row>
  </menu-layout>
</template>

<script>
import AssetGrid from "@/components/AssetGrid";
import FilterMixin from "@/mixins/FilterMixin";
import LoadLabelsMixin from "@/mixins/LoadLabelsMixin";
import FilterTree from "@/components/asset_filter/FilterTree";
import MenuLayout from "@/components/layout/MenuLayout";
import DropdownMenu from "@/components/layout/DropdownMenu";
import MyAssetActionsMixin from "@/mixins/MyAssetActionsMixin";
import Col2 from "@/components/layout/Col2";
import Row from "@/components/layout/Row";
import Col9 from "@/components/layout/Col9";
import Col12 from "@/components/layout/Col12";
import Col3 from "@/components/layout/Col3";

export default {
  name: "MyAssets",
  data: function () {
    return {
      items: [],
      class_name: '',
      translations: {},
      class_name_relabel: '',
      toggle_all: true,
      max_results: false,
      current_filter: false,
      loading: true,
      filters: false,
      limit: false,
      run_model_modal: false,
      asset_ids_selected: [],
      base_colors: [
        [249, 65, 68],
        [243, 114, 44],
        [248, 150, 30],
        [67, 170, 139],
        [249, 132, 74],
        [249, 199, 79],
        [144, 190, 109],
        [77, 144, 142],
        [87, 117, 144],
        [39, 125, 161],
        [249, 65, 68],
        [243, 114, 44],
        [248, 150, 30],
        [67, 170, 139],
      ]
    }
  },
  computed: {
    menu_items () {
      let menu_items = []
      if(this.items.length > 0) {
        menu_items.push({'title': this.$t('myassets.correct'), 'on_click': this.validate_all});
        // menu_items.push({'title': this.$t('myassets.all_images_in_filter_are_correct_limit_500'), 'on_click': this.validate_filter});
        menu_items.push({'title': this.$t('myassets.invalidate'), 'on_click': this.invalidate_all});
        menu_items.push({'title': this.$t('myassets.add_validation'), 'on_click': this.add_to_validation_set});
        // menu_items.push({'title': this.$t('myassets.add_all_images_in_filter_to_validation_set'), 'on_click': this.add_filter_to_validation_set});
        menu_items.push({'title': this.$t('myassets.remove_validation'), 'on_click': this.remove_from_validation_set});
        menu_items.push({'title': this.$t('myassets.delete'), 'on_click': this.delete_assets});
        // menu_items.push({'title': this.$t('myassets.delete_filter'), 'on_click': this.delete_assets_in_filter});
      }
      return menu_items
    },
    filters_includes_not_validated() {
      return JSON.parse(this.current_filter.filters).filter(el=>{return el.type === 9 && el.value === 'No'}).length > 0;
    },
    filters_includes_validated() {
      return JSON.parse(this.current_filter.filters).filter(el=>{return el.type === 9 && el.value === 'Yes'}).length > 0;
    },
    options() {
      return Object.values(this.translations);
    },
    nlToScientific() {
      let mapping = {}
      let values = Object.values(this.translations);
      let keys = Object.keys(this.translations);
      for (let i = 0; i < values.length; i++) {
        mapping[values[i]] = keys[i]
      }
      return mapping;
    }
  },
  components: {
    Col3,
    Col12,
    Col9,
    Row,
    Col2,
    DropdownMenu,
    MenuLayout,
    FilterTree,
    AssetGrid
  },
  mixins: [FilterMixin, MyAssetActionsMixin, LoadLabelsMixin], //Duplicated in MyBoxes
  methods: {
    update(filters, limit) {
      this.$set(this, 'filters', filters)
      this.$set(this, 'limit', limit)
    },
    removeItem(asset_id) {
      let filteredItems = this.items.filter((image) => {
        return image.id !== asset_id
      });
      this.$set(this, 'items', filteredItems);
    },
    update_selection(asset_ids) {
      this.$set(this, 'asset_ids_selected', asset_ids)
    },
    removeGridItem(asset_id) {
      this.removeItem(asset_id)
    },
    async load(filters, limit) {

      if(!filters) {
        filters = this.filters
        limit = this.limit
      }

      this.items = [];
      this.$set(this, 'loading', true)
      let items = await this.$api.asset.filter(filters, limit)
      let this2 = this;
      this.$api.asset.count_filter(filters).then((count) => {
        this2.$set(this2, 'max_results', count);
      });

      if (items.length === 0) {
        this.$set(this, 'max_results', 0);
        this.$set(this, 'loading', false)
      }

      items.map((item) => {
        try {
          item.annotations = item.relationships.annotations.map((annotation) => {
            let label = this.labels.find((label) => {
              return label.id === annotation.label_id
            });

      
            annotation.label = label;
            annotation.coords = JSON.parse(annotation.coords);
            if(label.color) {
              annotation.color_values = label.color.split(',')
            }
            else {
              let random_color = this.base_colors[Math.floor(Math.random() * this.base_colors.length)];
              annotation.color_values = random_color;
            }
            
            annotation.type = annotation.type === 1 ? 'box' : 'point'
            annotation.label = label.name;
            annotation.conf = annotation.certainty === null ? 1 : annotation.certainty;
            return annotation
          });
        } catch (error) {
          console.log(error);
          item.annotations = [];
        }

        item.checked = true
        item.path = this.$api_host + '/' + item.static_path
      });

      this.$set(this, 'items', items);
      this.$set(this,'loading',false);
    }
  },
  mounted() {
    if(this.labels.length > 0) {
      this.$set(this, 'loading', false)
    }
  },
  watch: {
    labels(value) {
      if(value.length > 0) {
        this.$set(this, 'loading', false)
      }
    },
    toggle_all(value) {
      for (let i = 0; i < this.items.length; i++) {
        this.$set(this.items[i], 'checked', value);
      }
    },
  },
}
</script>

<style scoped>

</style>