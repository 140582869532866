<template>
    <v-btn
            class="ma-2"
            icon
            small
            @click.native="pressed"
            color="blue lighten-2"
    >
        <v-icon>{{icon}}</v-icon>
    </v-btn>
</template>

<script>
  export default {
    name: "IconButton",
    props: {
      disabled: {default: false},
      icon: {default: 'far fa-trash-alt'}
    },
    methods: {
      pressed () {
        this.$emit('pressed')
      }
    }
  }
</script>

<style scoped>

</style>